import React from 'react'
import styled from '@emotion/styled'
import Text from './Text'
import Space from './Space'
import Button from './Button'
import { Wrapper as ScannerWrapper, BG as ScannerBG } from '../pageSlices/ScannerBanner'
import Scanner from '../form/Scanner'
import recommendationCardHeader from '../../../static/vector-elements/recommendation-card-header.svg'
import { theme } from '../../styles'

const BG = styled.div`
  position: absolute;
  background-color: ${theme.color.purple900};
  width: 500vw;
  margin-left: -300%;
  height: 100%;
  top: 0;

  @media (max-width: 1050px) {
    margin-left: -100%;
  }
`

const Wrapper = styled.div`
  grid-row: 4 / 5;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 80px;
  padding: 80px 0px;
  width: calc(100% + 150px);
  margin-left: -75px;

  @media (max-width: 1500px) {
    margin-left: 0px;
    width: 100%;
  }

  @media (max-width: 1050px) {
    grid-column-gap: 64px;
    padding: 80px 0px;
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-gap: 32px;
    padding: 64px 0px;

    * {
      text-align: center;
    }
  }
`

const Col = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
`

const ImgCol = styled(Col)`
  width: 100%;
  background-image: url(${recommendationCardHeader});
  background-size: 100%;
  background-position: top;
  background-repeat: no-repeat;
  box-shadow: 0px 0px 14px 3px ${theme.color.dropShadow};
  background-color: ${theme.color.offWhite};
`

const TextWrapper = styled.div`
  padding: 56px;

  @media (max-width: 1050px) {
    padding: 48px 32px;
  }
`

const BannerCTA = styled.div`
  grid-row: 5 / 6;
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 80px 150px;

  @media (max-width: 1050px) {
    padding: 64px 10px;
  }

  @media (max-width: 800px) {
    text-align: center;
  }
`

const ScoreFeature = ({ formVisible }) => (
  <>
    <ScannerWrapper hasTopPadding hasBottomPadding style={{ gridRow: 3 / 4 }} id="secondary-scan">
      <Text heading2 center>
        Scan New Site
      </Text>
      <Space height={32} />
      <Scanner partner secondaryScan />
      <ScannerBG className="bg" bgColor="purple200" />
    </ScannerWrapper>
    <Wrapper>
      <Col>
        <Text heading2>What Your Score Represents</Text>
        <Space height={32} />
        <Text>
          Your Accessibility Compliance Score is a baseline to understand where your website’s homepage stands today. If
          your rating is lower than average, your risk of liability is higher. As people visit your site, your score
          will change based on the number and severity of accessibility barriers they encounter. When active, AudioEye
          will improve a website's Score by an average of 25 points or more.
        </Text>
      </Col>
      <ImgCol>
        <TextWrapper>
          <Text color="purple500" bodyHeavy>
            Recommendation:
          </Text>
          <Space height={32} />
          <Text>
            With ADA <strong>lawsuits up over 300% in the past few years</strong>, finding, fixing, and monitoring your
            website for accessibility compliance is more important than ever. This score only represents your homepage,
            so we recommend starting a free trial to measure your entire website.
          </Text>
        </TextWrapper>
      </ImgCol>
    </Wrapper>
    <BannerCTA>
      <Text heading2 center color="white">
        Ready to get started with AudioEye?
      </Text>
      <Space height={32} />
      <Button
        text="Start Free Trial"
        to="#get-started-free"
        tabIndex={formVisible ? '-1' : '0'}
        disabled={!!formVisible}
        style={{ maxWidth: '250px', margin: '0 auto' }}
        buttonType="tertiary reversed"
      />
      <BG className="bg" />
    </BannerCTA>
  </>
)

export default ScoreFeature
