/* eslint-disable indent */
import React from 'react'
import styled from '@emotion/styled'
import Text from './Text'
import Space from './Space'
import Button from './Button'
import { theme } from '../../styles'
import violetCheckMarkIcon from '../../../static/icons/check-icons/violet-check-icon.svg'

const BannerContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
  grid-row: 1 / 2;

  &:after {
    position: absolute;
    content: '';
    top: 0;
    z-index: -1;
    background-color: ${theme.color.offWhite};
    width: 500vw;
    margin-left: -300%;
    height: 100%;
  }
`

const BannerWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-gap: 48px;
  justify-content: space-between;
  justify-items: center;
  padding: 80px 0px;
  padding-top: 64px;
  padding-bottom: 0px;

  @media (max-width: 1050px) {
    grid-template-columns: 1fr;
    grid-gap: 32px;
    padding: 32px 0px 20px 0px;
    padding-top: 32px;
    padding-bottom: 8px;
  }
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  width: 100%;

  @media (max-width: 1050px) {
    min-width: unset;
    align-self: center;
    justify-content: flex-start;
    align-items: center;

    * {
      margin: 0 auto;
      text-align: center !important;
    }
  }
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1500px) {
    justify-content: flex-start;
    max-width: 600px;
  }

  @media (max-width: 1050px) {
    max-width: 80vw;
    margin: 0 auto;
  }

  @media (max-width: 800px) {
    display: none;
  }
`

const TitleText = styled(Text)`
  span {
    color: ${theme.color.red500};
    font-size: ${theme.fonts.size.heading1};
    font-family: ${theme.fonts.family.heading1};
    line-height: 1;
  }
  @media (max-width: 800px) {
    h1 {
      width: calc(100% + 12px);
      margin-left: -6px;
    }
  }
`

const BannerText = styled(Text)`
  span {
    color: ${theme.color.purple500};
    font-family: ${theme.fonts.family.heading1};
  }
`

const CheckMarkListWrapper = styled(Text)`
  ul {
    margin-top: 24px;
    margin-left: 20px;
  }
  li {
    padding-inline-start: 12px;
    list-style-type: none;
    list-style-image: url(${violetCheckMarkIcon});

    @media (max-width: 1050px) {
      text-align: left;
    }

    @media (max-width: 800px) {
      text-align: left !important;
      margin-bottom: 16px;
    }
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;

  .button.primary,
  .button.secondary {
    min-width: 200px;

    @media (max-width: 1050px) {
      margin: 0px 8px;
    }

    @media (max-width: 800px) {
      margin: 0 auto;
    }
  }

  @media (max-width: 800px) {
    flex-direction: column;
    width: 100%;
  }
`

const Image = styled.img`
  object-fit: cover;
  width: 100%;

  @media (max-width: 1050px) {
    margin: 0 auto 20px auto;
  }
`

const ScoreBannerTitle = ({ score, url, headingRef }) => (
  <BannerContainer>
    <BannerWrapper hasTopPadding hasBottomPadding>
      <InfoWrapper>
        <TitleText heading1 aria-level="2" style={{ marginBottom: '1.45rem' }} ref={headingRef}>
          We found <span>{score} issues</span> on your page
        </TitleText>
        <BannerText bodyHeavy>
          Let's make <span>{url || 'your site'}</span> more accessible with AudioEye
        </BannerText>
        <CheckMarkListWrapper>
          <ul>
            <li>Automated fixes for most common accessibility issues</li>
            <li>Detailed reporting on issues detected on your site</li>
            <li>ADA and WCAG compliance plan from day one of installation</li>
          </ul>
        </CheckMarkListWrapper>
        <Space height={24} tabletHeight={16} />
        <ButtonWrapper>
          <Button text="Start Free Trial" to="#get-started-free" className="primary" />
          <Space height={16} width={16} tabletHeight={12} mobileWidth={0} mobileHeight={0} />
        </ButtonWrapper>
      </InfoWrapper>
      <ImageWrapper>
        <Image
          src="https://images.prismic.io/audioeye-web/db92d6ca-9277-4119-994a-cc1177289bdd_audioeye-accessibility-issues-on-scan.png?auto=compress,format"
          alt="website wireframe with accessibility issues highlighted throughout the page"
        />
      </ImageWrapper>
    </BannerWrapper>
  </BannerContainer>
)

export default ScoreBannerTitle
