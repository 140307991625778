import React from 'react'
import styled from '@emotion/styled'
import { navigate } from 'gatsby'
import Text from './Text'
import TextLink from './TextLink'
import Button from './Button'
import Space from './Space'
import Tag from './Tag'
import chevronIconOffBlack from '../../../static/icons/arrow-icons/chevron-icon-offBlack.svg'
import { NavButton } from '../form/common'

const BoxWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgb(40 14 72 / 11%);
  border-radius: 10px;
  padding: 32px 40px;
  display: flex;
  flex-direction: column;
  align-items: ${p => (p.error ? 'center' : 'flex-start')};
  text-align: ${p => (p.error ? 'center' : 'left')};
  grid-column: 1 / 4;
  position: relative;

  @media (max-width: 1050px) {
    padding: 24px 32px;
  }

  @media (max-width: 800px) {
    padding: 24px;
    margin: ${p => (p.error ? '0px auto 16px auto' : '0 auto')};
    grid-column: 1 / -1;
    height: auto;
  }
`

const BoxHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ScoreTextWrapper = styled.div`
  display: flex;
  align-items: baseline;
`

const ScoreBox = ({ scoreData, error, existingCust, URL, formVisible }) => (
  <BoxWrapper error={error || existingCust}>
    {existingCust && (
      <>
        <Text heading3>This account already exists.</Text>
        <Space height={16} />
        <Text smallBodyHeavy>Please login to your AudioEye Portal to add additional websites.</Text>
        <Space height={24} />
        <Button
          text="AudioEye Portal"
          to={`${process.env.GATSBY_AEYE_PORTAL_LOGIN_URL}?email=${encodeURIComponent(URL)}`}
        />
        <Space height={16} />
        <TextLink to="https://audioeye.zendesk.com/hc/en-us" text="Help Center" />
      </>
    )}
    {error && (
      <>
        <NavButton
          tabIndex="0"
          aria-label="back"
          role="button"
          className="left focus-outline"
          onClick={() => navigate('/')}
          onKeyDown={e => {
            if (e.key === 'Enter') navigate('/')
          }}
        >
          <img alt="" src={chevronIconOffBlack} style={{ transform: 'rotate(90deg)' }} />
        </NavButton>
        {error === 'failed' ? (
          <div style={{ display: 'inline-block' }}>
            <Text style={{ display: 'inline' }} body color="red500">
              The site entered is blocking us from accurately calculating a score. An AudioEye representative will be in
              touch soon to help you better understand your state of accessibility.{' '}
            </Text>
            <br />
            <TextLink
              font="body"
              to="/how-to-get-your-accessibility-score"
              text="You can also learn more about how to get your score"
            />
            <Text style={{ display: 'inline' }} body color="red500">
              {' '}
              or sign up for a free trial and activate AudioEye on your website.
            </Text>
          </div>
        ) : (
          <Text largeBody color="red500">
            {error}
          </Text>
        )}
        <Space height={24} />
        <Button text="Start Free Trial" to="#get-started-free" />
      </>
    )}
    {!error && !existingCust && (
      <>
        <BoxHeader>
          <Text smallCapsSans>Accessibility Compliance Score</Text>
          <Tag text="Not Compliant" color="red" />
        </BoxHeader>
        <Space height={16} />
        <ScoreTextWrapper>
          <Text color="red500" className="display-red" style={{ marginRight: '4px' }}>
            {scoreData.score}
          </Text>
          <Text bodyHeavy> / 100</Text>
        </ScoreTextWrapper>
        <Text smallBody>
          This score only represents one web page from the URL entered. Get a full detailed report of detected
          accessibility and compliance issues across your entire website when you sign up.
        </Text>
        <Space height={32} mobileHeight={24} />
        <Button
          buttonType="link CTA"
          text="Start Free Trial"
          to="#get-started-free"
          style={{ marginTop: 'auto' }}
          tabIndex={formVisible ? '-1' : '0'}
        />
      </>
    )}
  </BoxWrapper>
)

export default ScoreBox
